<template>
    <div id="home" class="pages">
        <div id="inspirations" class="part">
            <h4 class="part-name text-uppercase fw-bold" v-html="$t('Home.parts.inspirations')"/>
            <InspirationsCards/>
        </div>
        <div id="good-moment" class="part">
            <h4 class="part-name text-uppercase fw-bold" v-html="$t('Home.parts.goodMoment')"/>
            <div class="images-row">
                <AdvertisingCard type="spécial vacances d'été" :number="12"/>
            </div>
        </div>
        <div class="images-row">
            <SpotlightCard v-if="products" id="spotlight-1" type="heart-stroke" :book="products[1]"/>
            <SpotlightCard v-if="products" id="spotlight-2" type="reviews" :book="products[1]"/>
        </div>
        <div class="images-row parent-book-and-finder position-relative">
            <div class="d-flex book-and-finder h-100">
                <BedsideBook v-if="products" :book="products[1]"/>
                <PublishersList/>
            </div>
        </div>
        <div v-if="products" class="part pb-4">
            <h4 class="part-name text-uppercase fw-bold" v-html="$t('Home.parts.fiction')"/>
            <BookSlider v-if="products" :books="products" :nb-window="6"/>
        </div>
        <div v-if="products" class="part pb-4">
            <h4 class="part-name text-uppercase fw-bold" v-html="$t('Home.parts.art')"/>
            <BookSlider v-if="products" :books="products" :nb-window="6"/>
        </div>
        <div v-if="products" class="part pb-4">
            <h4 class="part-name text-uppercase fw-bold" v-html="$t('Home.parts.hobbies')"/>
            <BookSlider v-if="products" :books="products" :nb-window="6"/>
        </div>
        <div v-if="products" class="part pb-4">
            <h4 class="part-name text-uppercase fw-bold" v-html="$t('Home.parts.knowledge')"/>
            <BookSlider v-if="products" :books="products" :nb-window="6"/>
        </div>
        <div class="pb-2 fs-4 text-dark" v-html="$t('Product.booksSeen')"/>
        <div class="d-lg-none">
            <AlreadySeenBookSlider v-if="products" :books="products" :nb-window="4"/>
            <ShoppingBasket class="mt-3"/>
        </div>
        <div class="d-none d-lg-flex slider-and-shopping row h-100">
            <div class="col-8 pe-5">
                <AlreadySeenBookSlider v-if="products" :books="products" :nb-window="4"/>
            </div>
            <div class="col-4">
                <ShoppingBasket/>
            </div>
        </div>
    </div>
</template>

<script>
import InspirationsCards from "@/components/Shared/InspirationsCards.vue";
import AdvertisingCard from "@/components/Shared/AdvertisingCard/AdvertisingCard.vue";
import SpotlightCard from "@/components/Shared/SpotlightCard/SpotlightCard.vue";
import BookSlider from "@/components/Shared/Slider/BookSlider/BookSlider.vue";
import AlreadySeenBookSlider from "@/components/Shared/Slider/AlreadySeen/BookSlider/BookSlider.vue";
import BedsideBook from "@/components/Home/BedsideBook/BedsideBook.vue";
import PublishersList from "@/components/Home/PublishersList/PublishersList.vue";
import ShoppingBasket from "@/components/Shared/ShoppingBasket/ShoppingBasket.vue";

export default {
    name: "Home",
    components: { InspirationsCards, AdvertisingCard, SpotlightCard, BookSlider, BedsideBook, PublishersList, ShoppingBasket, AlreadySeenBookSlider },
    data: () => ({ products: undefined }),
    async created() {
        const { data } = await this.$gutenbergAPI.getProducts({ "page": 4, "per-page": 12 });
        this.products = data.products;
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

#home {
    @include media-breakpoint-up(md) {
        #spotlight-1 {
            margin-right: .5rem;
        }

        #spotlight-2 {
            margin-left: .5rem;
        }
    }

    @include media-breakpoint-up(lg) {
        #spotlight-1 {
            margin-right: 1rem;
        }

        #spotlight-2 {
            margin-left: 1rem;
        }
    }

    #good-moment {
        @include media-breakpoint-up(md) {
            margin-bottom: .5rem;
        }
    }

    #inspirations {
        .images-row {
            margin: 0;

            @include media-breakpoint-up(md) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .part-name {
        margin: 0;
        font-size: 5vw;

        @include media-breakpoint-up(md) {
            font-size: 2vw;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 1.5vw;
            padding: 0 .25rem;
        }
    }

    .images-row {
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            margin: .25rem;
        }

        @include media-breakpoint-up(xxl) {
            margin-bottom: 1.5rem;
        }
    }

    .parent-book-and-finder {
        padding-bottom: 68%;

        @include media-breakpoint-up(sm) {
            padding-bottom: 25%;
        }
    }

    .book-and-finder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
</style>