<template>
    <div class="splide__slide">
        <div
        class="book-frame d-flex position-relative justify-content-center align-items-end"
        @click="$router.push(`/${book._id}`)">
            <div class="position-absolute w-100 h-100">
                <div class="wood-background w-100 h-100 img-fluid"/>
            </div>
            <div class="padding-border h-100 d-flex align-items-end">
                <img :src="book.firstCoverPhotoUrl || bookPNG" class="book" :alt="`Book: ${book.title} from ${book.author}`"/>
            </div>
        </div>
        <div class="wood-border"/>
        <div class="description text-dark">
            <div class="w-100 position-relative price-row">
                <div class="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                    <div class="icons add"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import woodBackgroundJPG from "@/assets/img/wood-vertical.jpg";
import bookPNG from "@/assets/img/book.png";

export default {
    name: "BookWindow",
    props: {
        book: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        woodBackgroundJPG,
        bookPNG,
    }),
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '../../../../../assets/scss/_colors.scss';

.splide__slide {
    .book-frame {
        img {
            box-shadow: 10px 5px 10px rgba(170, 170, 170, .5);
        }
    }
}
</style>