import { render, staticRenderFns } from "./BedsideBook.vue?vue&type=template&id=680f6dd2&scoped=true&"
import script from "./BedsideBook.vue?vue&type=script&lang=js&"
export * from "./BedsideBook.vue?vue&type=script&lang=js&"
import style0 from "./BedsideBook.vue?vue&type=style&index=0&id=680f6dd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680f6dd2",
  null
  
)

export default component.exports