<template>
    <div class="pages text-dark">
        <div class="pb-3 d-flex align-items-end">
            <div class="icons home"/>
            <div v-html="`> ${getTitle}`"/>
        </div>
        <ProductCard v-if="book" :book="book"/>
        <div class="fw-bold part mt-3" v-html="$t('Product.sameAutor')"/>
        <BookSlider v-if="products" :books="products" :nb-window="6"/>
        <div class="fw-bold part" v-html="$t('Product.sameSection')"/>
        <BookSlider v-if="products" :books="products" :nb-window="6"/>
        <div class="pb-2 fs-4" v-html="$t('Product.booksSeen')"/>
        <div class="d-lg-none">
            <AlreadySeenBookSlider v-if="products" :books="products" :nb-window="4"/>
            <ShoppingBasket class="mt-3"/>
        </div>
        <div class="d-none d-lg-flex slider-and-shopping row h-100">
            <div class="col-8 pe-5">
                <AlreadySeenBookSlider v-if="products" :books="products" :nb-window="4"/>
            </div>
            <div class="col-4">
                <ShoppingBasket/>
            </div>
        </div>
    </div>
</template>

<script>
import BookSlider from "@/components/Shared/Slider/BookSlider/BookSlider.vue";
import AlreadySeenBookSlider from "@/components/Shared/Slider/AlreadySeen/BookSlider/BookSlider.vue";
import ProductCard from "@/components/Product/ProductCard/ProductCard.vue";
import ShoppingBasket from "@/components/Shared/ShoppingBasket/ShoppingBasket.vue";

export default {
    name: "Product",
    components: { BookSlider, ProductCard, ShoppingBasket, AlreadySeenBookSlider },
    data: () => ({
        products: undefined,
        book: undefined,
    }),
    computed: {
        getTitle() {
            if (!this.book) {
                return "";
            }
            return this.book.title;
        },
    },
    watch: {
        $route() {
            this.getProduct();
        },
    },
    created() {
        this.getProduct();
        this.getProducts();
    },
    methods: {
        async getProduct() {
            const { data } = await this.$gutenbergAPI.getProduct(this.$route.params.bookId);
            this.book = data;
        },
        async getProducts() {
            const { data } = await this.$gutenbergAPI.getProducts({ "page": 4, "per-page": 12 });
            this.products = data.products;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.part {
    font-size: 1.5rem;
}
</style>