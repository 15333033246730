<template>
    <div class="login-form">
        <div>
            <div class="inline-grid w-100">
                <div>
                    <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.email')"/>
                    <span>
                        *
                    </span>
                </div>
                <div class="border-input">
                    <input v-model="email" class="w-100" placeholder="Requis"/>
                </div>
            </div>
        </div>
        <div>
            <div class="inline-grid w-100">
                <div>
                    <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.password')"/>
                    <span>
                        *
                    </span>
                </div>
                <div class="border-input">
                    <input v-model="password" class="w-100" placeholder="Requis" type="password"/>
                </div>
            </div>
        </div>
        <div id="error" class="d-none text-danger fs-6" v-html="$t('NavBar.iconsModals.profile.login.bad-credentials')"/>
        <div class="d-flex align-items-center">
            <input v-model="remembered" class="me-2 cursor-pointer" type="checkbox"/>
            <div v-html="$t('NavBar.iconsModals.profile.remind-me')"/>
        </div>
        <div
        class="w-100 d-flex primary-color fw-bold py-2 forgot-password"
        v-html="$t('NavBar.iconsModals.profile.login.forgotten-password')"/>
        <div class="button" @click="login">
            <div class="fw-bold" v-html="$t('NavBar.iconsModals.profile.login.connexion')"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginTab",
    data() {
        return {
            email: "",
            password: "",
            remembered: false,
        };
    },
    methods: {
        login() {
            const data = {
                email: this.email,
                password: this.password,
            };
            this.$gutenbergAPI.login(data).then(() => {
                if (document.getElementById("error").classList.contains("d-none")) {
                    document.getElementById("error").classList.add("d-none");
                }
            }).catch(() => {
                document.getElementById("error").classList.remove("d-none");
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.login-form {
    width: 100%;
    font-size: .9rem;

    @include media-breakpoint-up(lg) {
        width: 25rem;
        font-size: 1rem;
    }

    .inline-grid {
        display: inline-grid;
        padding: 1.3rem 0;
    }

    span {
        color: red;
    }

    input {
        border: 0;
    }

    .border-input {
        border-top: 5px solid $green-color;
    }

    .forgot-password {
        font-size: .65rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }

    .button {
        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }

        @include media-breakpoint-down(md) {
            width: 75%;
            font-size: .9rem;
            height: 2rem;
        }
    }

    .forgot-password, .button {
        @include media-breakpoint-down(sm) {
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            margin: auto;
        }
    }
}
</style>