<template>
    <div id="footer"/>
</template>

<script>
export default { name: "ShopFooter" };
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

#footer {
    width: 100%;
    height: 45vh;
    background-color: $primary-color;
}
</style>