var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Splide',{attrs:{"options":{
    rewind: false,
    perPage: _vm.getSlidesNumber,
    perMove: 1,
    arrows: true,
    pagination: false,
    breakpoints: {
        576: {
            perPage: 2,
        },
        768: {
            perPage: 3,
        },
        1200: {
            perPage: 4,
        },
    },
}},on:{"splide:move":_vm.onMove},scopedSlots:_vm._u([{key:"controls",fn:function(){return [_c('div',{staticClass:"splide__arrows",class:_vm.getSlidesNumber <= 4 ? 'd-none' : null},[_c('button',{ref:"left-arrow",staticClass:"splide__arrow splide__arrow--prev arrow-left icons d-none"}),_c('button',{ref:"right-arrow",staticClass:"splide__arrow splide__arrow--next arrow-right icons"})])]},proxy:true}])},_vm._l((_vm.books),function(item,i){return _c('SplideSlide',{key:i},[_c('BookWindow',{attrs:{"book":item}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }