<template>
    <div class="splide__slide">
        <div
        class="book-frame d-flex position-relative justify-content-center align-items-end">
            <div class="w-100 position-absolute top-0 right-0">
                <div class="icons float-end star border-0 mx-1"/>
            </div>
            <div
            class="bg position-absolute w-100 h-100"
            @click="$router.push(`/${book._id}`)">
                <div class="wood-background w-100 h-100 img-fluid"/>
            </div>
            <div
            class="padding-border h-100 d-flex align-items-end"
            @click="$router.push(`/${book._id}`)">
                <img :src="book.firstCoverPhotoUrl || bookPNG" class="book" :alt="`Book: ${book.title} from ${book.author}`"/>
            </div>
        </div>
        <div class="wood-border"/>
        <div class="description text-dark">
            <div class="title fw-bold font-baskerville text-center" v-html="book.title"/>
            <div class="fst-italic text-truncate font-baskerville text-center" v-html="book.author"/>
            <div class="w-100 position-relative price-row">
                <Price v-if="book.price" class="price fw-bold" :price="book.price"/>
                <div class="position-absolute w-100 h-100 top-0 d-flex justify-content-between align-items-center">
                    <div class="icons add"/>
                    <div class="icons border-shop"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import woodBackgroundJPG from "@/assets/img/wood-vertical.jpg";
import bookPNG from "@/assets/img/book.png";
import Price from "@/components/Shared/Buttons/PurchasedButton/Price/Price";

export default {
    name: "BookWindow",
    components: { Price },
    props: {
        book: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        woodBackgroundJPG,
        bookPNG,
    }),
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '../../../../assets/scss/_colors.scss';

.splide__slide {
    &:hover {
        .description {
            transition: background-color .2s ease-in-out;
            background-color: #ead7bb;
        }
    }

    .icons {
        transition: .2s ease-in-out;
    }

    .book-frame {
        .wood-background {
            background-image: url("../../../../assets/img/wood-horizontal.svg");
            background-repeat: repeat;
            background-position: center;
        }

        img {
            box-shadow: 10px 5px 13px rgba(0, 0, 0, .4);
        }
    }

    .description {
        .title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .add {
            background-position: left !important;
        }

        .border-shop {
            background-position: right !important;
        }

        .price {
            font-size: 1rem;

            @include media-breakpoint-up(lg) {
                font-size: 1.25rem;
            }
        }
    }
}
</style>