<template>
    <div id="posModal" class="d-none d-flex flex-column">
        <div class="d-md-none title d-flex justify-content-between">
            <div class="d-flex align-items-center fw-bold" v-html="$t('NavBar.iconsModals.pos.title')"/>
            <div
            class="d-md-none icons pos-transparent"
            @click="showModal('pos')"/>
        </div>
        <div class="search-box d-flex">
            <div class="d-flex w-100 search-border">
                <input ref="search" class="w-100 h-100 border-0" :placeholder="$t('NavBar.iconsModals.pos.title')"/>
                <div
                class="icons cross"
                @click="showModal('pos')"/>
            </div>
            <div class="icons ok-red"/>
        </div>
        <div class="content w-100 position-relative">
            <div class="border-primary-color position-absolute w-100 top-0 start-0"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "PosModal",
    props: { showModal: { type: Function, required: true } },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';

#posModal {
    background-color: $pos-color;
    font-size: 1rem;

    input {
        padding: .3rem;

        @include media-breakpoint-up(xl) {
            padding: 1rem;
        }
    }

    .search-box {
        background-color: $pos-color;
        border: .7rem solid $pos-color;
        border-right: 0;
        height: 3rem;

        .icons {
            height: 100% !important;
            width: $header-height / 2;
        }

        @include media-breakpoint-up(md) {
            height: $header-height-md / 2;

            .icons {
                height: 100% !important;
                width: $header-height-md / 2;
            }
        }

        @include media-breakpoint-up(lg) {
            height: $header-height-lg / 2;
            overflow-x: initial;
            border: .85rem solid $pos-color;

            .icons {
                height: 100% !important;
                width: $header-height-lg / 2;
            }
        }

        @include media-breakpoint-up(xxl) {
            height: $header-height-xxl / 2;

            .icons {
                height: 100% !important;
                width: $header-height-xxl / 2;
            }
        }
    }

    .content {
        padding: .5rem 1.5rem;
        color: $primary-color;
        border: .75rem solid $pos-color;
        border-top: 0;
        padding-top: 0;

        @include media-breakpoint-up(md) {
            border: .9rem solid $pos-color;
            border-top: 0;
        }
    }
}
</style>