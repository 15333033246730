<template>
    <div class="misc h-100 flex-column justify-content-between pt-2">
        <div class="d-flex flex-column liked">
            <div class="fw-bold primary-color" v-html="$t('Product.content.liked.title')"/>
            <div class="d-flex py-2 border border-1 border-dark border-bottom-0 border-end-0 border-start-0 position-relative">
                <img :src="heartSVG" alt="arrow-heart" class="liked-icons"/>
                <div class="ps-2" v-html="$t('Product.content.liked.heart-stroke')"/>
                <div class="position-absolute end-0 d-flex align-items-center">
                    <div class="icons reversed-add"/>
                </div>
            </div>
            <div class="d-flex py-2 stars-border position-relative">
                <img :src="moonSVG" alt="arrow-moon" class="liked-icons"/>
                <div class="ps-2" v-html="$t('Product.content.liked.bedsidebook')"/>
                <div class="position-absolute end-0 d-flex align-items-center">
                    <div class="icons reversed-add"/>
                </div>
            </div>
            <div class="fw-bold primary-color pt-2" v-html="$t('Product.content.keyword')"/>
            <div v-html="'Roman | autre mot clé | autre mot clé | autre mot clé | autre mot clé'"/>
        </div>
        <PurchasedButton :price="price"/>
    </div>
</template>

<script>
import PurchasedButton from "@/components/Shared/Buttons/PurchasedButton/PurchasedButton";
import heartSVG from "@/assets/img/logos/heart-logo.svg";
import moonSVG from "@/assets/img/icons/yellow-star-icon.svg";

export default {
    name: "ProductFooter",
    components: { PurchasedButton },
    props: {
        price: {
            type: Number,
            required: true,
        },
    },
    data: () => ({ moonSVG, heartSVG }),
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.liked {
    @include media-breakpoint-up(md) {
        font-size: .75rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }
}

.reversed-add {
    width: 1.6rem;
    height: 1.6rem;
}

.stars-border {
    border-top: 1px solid black;

    @include media-breakpoint-up(md) {
        border-bottom: 1px solid black;
    }
}

.liked-icons {
    width: 4rem !important;
    height: 2rem;
}
</style>