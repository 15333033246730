<template>
    <div class="register-form d-flex flex-column">
        <div class="row-one">
            <div class="column-one">
                <div class="inline-grid">
                    <div>
                        <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.register.firstName')"/>
                        <span>
                            *
                        </span>
                    </div>
                    <div class="border-input">
                        <input v-model="firstName" class="w-100" placeholder="Requis"/>
                    </div>
                </div>
                <div class="inline-grid">
                    <div>
                        <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.register.lastName')"/>
                        <span>
                            *
                        </span>
                    </div>
                    <div class="border-input">
                        <input v-model="lastName" class="w-100" placeholder="Requis"/>
                    </div>
                </div>
                <div class="inline-grid">
                    <div>
                        <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.email')"/>
                        <span>
                            *
                        </span>
                    </div>
                    <div class="border-input">
                        <input v-model="email" class="w-100" placeholder="Requis"/>
                    </div>
                </div>
            </div>
            <div class="column-two">
                <div class="inline-grid">
                    <div>
                        <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.password')"/>
                        <span>
                            *
                        </span>
                    </div>
                    <div class="border-input">
                        <input v-model="password" class="w-100" placeholder="Requis" type="password"/>
                    </div>
                </div>
                <div class="inline-grid">
                    <div>
                        <label class="fw-bold" v-html="$t('NavBar.iconsModals.profile.register.confirmPwd')"/>
                        <span>
                            *
                        </span>
                    </div>
                    <div class="border-input">
                        <input v-model="confirmedPwd" class="w-100" placeholder="Requis" type="password"/>
                    </div>
                </div>
                <div class="inline-grid">
                    <div class="d-flex align-items-center">
                        <input v-model="isProfesional" class="me-2 cursor-pointer" type="checkbox"/>
                        <div class="fw-bold" v-html="$t('NavBar.iconsModals.profile.register.profesional')"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-two">
            <div id="error" class="d-none text-danger fs-6" v-html="$t('NavBar.iconsModals.profile.login.bad-credentials')"/>
            <div class="inline-grid">
                <div class="d-flex align-items-center">
                    <input v-model="remembered" class="me-2 cursor-pointer" type="checkbox"/>
                    <div v-html="$t('NavBar.iconsModals.profile.remind-me')"/>
                </div>
            </div>
            <div class="button">
                <div class="fw-bold" v-html="$t('NavBar.iconsModals.profile.register.title')"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisterTab",
    data() {
        return {
            email: "",
            password: "",
            confirmedPwd: "",
            firstName: "",
            lastName: "",
            isProfesional: false,
            remembered: false,
        };
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.register-form {
    width: 100%;
    font-size: .9rem;

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }

    .row-one {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        @include media-breakpoint-down(lg) {
            -webkit-box-direction: normal;
            -webkit-box-orient: vertical;
            -moz-box-direction: normal;
            -moz-box-orient: vertical;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .column-one {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 25rem;
                margin-right: 3.5rem;
            }
        }

        .column-two {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 25rem;
                margin-right: 3.5rem;
            }
        }

        .inline-grid {
            width: 100%;

            @include media-breakpoint-up(lg) {
                margin-right: 3.5rem;
            }
        }
    }

    .row-two {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 25rem;
        }
    }

    .inline-grid {
        display: inline-grid;
        padding: 1.3rem 0;
    }

    span {
        color: red;
    }

    input {
        border: 0;
    }

    .border-input {
        border-top: 5px solid $green-color;
    }

    .forgot-password {
        font-size: .65rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }

    .button {
        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }

        @include media-breakpoint-down(md) {
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            width: 75%;
            font-size: .9rem;
            height: 2rem;
            margin: auto;
        }
    }
}
</style>