<template>
    <div id="shopModal" class="d-none d-flex flex-column">
        <div class="title d-flex justify-content-between">
            <div class="d-flex align-items-center fw-bold" v-html="$t('NavBar.iconsModals.shop.title')"/>
            <div class="d-flex align-items-center">
                <div class="d-none d-md-flex button h-100">
                    <div class="fw-bold text-center" v-html="$t('NavBar.iconsModals.buttonFollowPurchase')"/>
                </div>
                <div
                class="d-none d-md-block icons cross"
                @click="showModal('shop')"/>
                <div
                class="d-md-none icons header-shop-transparent"
                @click="showModal('shop')"/>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center d-md-none orange-background">
            <div class=" button">
                <div class="fw-bold text-center" v-html="$t('NavBar.iconsModals.buttonFollowPurchase')"/>
            </div>
            <div
            class="icons cross"
            @click="showModal('shop')"/>
        </div>
        <div class="content w-100 position-relative inline-grid p-3">
            <div class="d-flex fw-bold primary-color align-item-center pb-2">
                <div class="total d-flex align-items-center pe-4 bottom-0" v-html="$t('NavBar.iconsModals.shop.totalCommand')"/>
                <Price class="price" :price="18"/>
            </div>
            <BookSlider :books="books" :nb-window="books.length"/>
            <div class="d-flex">
                <div class="process d-flex">
                    <div class="w-100 d-flex flex-column align-items-center text-decoration-underline">
                        <div class="text-center blue-square mb-2 fw-bold" v-html="'1'"/>
                        <div class="fw-bold separation text-center cursor-pointer" v-html="$t('NavBar.iconsModals.shop.step-one')"/>
                    </div>
                    <div class="w-100 d-flex flex-column align-items-center text-decoration-underline">
                        <div class="text-center blue-square mb-2 fw-bold" v-html="'2'"/>
                        <div class="fw-bold separation text-center cursor-pointer" v-html="$t('NavBar.iconsModals.shop.step-two')"/>
                    </div>
                    <div class="w-100 d-flex flex-column align-items-center">
                        <div class="text-center blue-square mb-2 fw-bold" v-html="'3'"/>
                        <div class="button orange">
                            <div class="fw-bold text-center" v-html="$t('NavBar.iconsModals.shop.step-three')"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BookSlider from "@/components/Shared/Slider/BookSlider/BookSlider";
import Price from "@/components/Shared/Buttons/PurchasedButton/Price/Price";

export default {
    name: "ShopModal",
    components: { BookSlider, Price },
    props: { showModal: { type: Function, required: true } },
    data() {
        return {
            books: [
                { price: 18, title: "test", author: "author" },
                { price: 18, title: "test", author: "author" },
                { price: 18, title: "test", author: "author" },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

.orange-background {
    background-color: $basket-color;
    border: .75rem solid $basket-color;
    border-bottom: .5rem;
    border-top: .5rem;

    .button {
        font-size: .8rem;
        width: 16rem;
        height: 2rem;
    }
}

#shopModal {
    background-color: $basket-color;

    .title {
        .button {
            max-width: 20rem;
            width: 20rem;

            &:hover {
                color: white;
                border-color: white;
            }

            @include media-breakpoint-only(md) {
                font-size: 1rem;
            }
        }
    }

    .content {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box: 1;
        -moz-box: 1;
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
        border: .75rem solid $basket-color;
        border-top: 0;
        padding-top: 0;

        @include media-breakpoint-up(md) {
            border: .9rem solid $basket-color;
            border-top: 0;
        }

        .total {
            font-size: .9rem;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
        }

        .price {
            font-size: 1.8rem;
        }

        .process {
            width: 100%;
            font-size: 1.25rem;

            @include media-breakpoint-down(md) {
                -webkit-box-direction: normal;
                -webkit-box-orient: vertical;
                -moz-box-direction: normal;
                -moz-box-orient: vertical;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: center;
                -moz-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
            }

            .blue-square {
                background-color: $primary-color;
                width: 2.5rem;
                height: 2.5rem;
                font-size: 1.5rem;
                color: white;
                border-radius: 50px;
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                display: -webkit-box;
                display: -moz-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -moz-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
            }

            .button {
                max-width: 16rem;

                & > div {
                    font-size: 1rem;
                }
            }

            & > div {
                padding: 1rem 0;
            }
        }
    }

    .separation {
        border-top: 6px solid $basket-color;
        padding: .5rem 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 16rem;
        }
    }

    .col-4 > div {
        -webkit-box: 1;
        -moz-box: 1;
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}
</style>