<template>
    <div class="header-icons">
        <div id="icon-container-catalog" class="d-flex align-items-center h-100 w-100">
            <div id="catalog" class="icons catalog position-relative" @click="showModal('catalog')">
                <span class="position-absolute justify-content-center text-center
                    w-100 d-none d-xl-flex" v-html="$t('NavBar.iconsModals.catalog.title')"/>
            </div>
        </div>
        <div id="icon-container-help" class="d-flex align-items-center h-100 w-100">
            <div id="help" class="icons help position-relative" @click="showModal('help')">
                <span class="position-absolute justify-content-center text-center
                    w-100 d-none d-xl-flex" v-html="$t('NavBar.iconsModals.help.title')"/>
            </div>
        </div>
        <div id="icon-container-pos" class="d-flex align-items-center h-100 w-100">
            <div id="pos" class="icons pos position-relative" @click="showModal('pos')">
                <span class="position-absolute justify-content-center text-center
                    w-100 d-none d-xl-flex" v-html="$t('NavBar.iconsModals.pos.title')"/>
            </div>
        </div>
        <div id="icon-container-profile" class="d-flex align-items-center h-100 w-100">
            <div id="profile" class="icons profile position-relative" @click="showModal('profile')">
                <span class="position-absolute justify-content-center text-center
                    w-100 d-none d-xl-flex" v-html="$t('NavBar.iconsModals.profile.title')"/>
            </div>
        </div>
        <div id="icon-container-shop" class="d-flex flex-column justify-content-center h-100 w-100">
            <div id="shop" class="icons header-shop position-relative" @click="showModal('shop')">
                <span class="position-absolute justify-content-center text-center
                    w-100 d-none d-xl-flex" v-html="$t('NavBar.iconsModals.shop.title')"/>
            </div>
        </div>
        <div id="icon-container-search" class="d-flex align-items-center h-100 w-100">
            <div id="search" class="icons search position-relative" @click="showModal('search')">
                <span class="position-absolute justify-content-center text-center
                    w-100 d-none d-xl-flex" v-html="$t('NavBar.iconsModals.search.title')"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavTop",
    props: {
        showModal: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';

span {
    font-size: .8rem;
    bottom: -3px;
    opacity: 0;
    line-height: 1;
}

.header-icons {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    @include media-breakpoint-down(md) {
        background-color: #fff;
    }

    .icon-title {
        font-size: .75rem;
    }

    .icons {
        height: 100%;
        width: 100%;

        &:hover {
            span {
                transition: .3s ease-in-out;
                opacity: 1;
            }
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                transform: scale(.75, .75);
            }
        }

        @include media-breakpoint-up(xxl) {
            height: 90%;
        }
    }

    .selected {
        background-color: $primary-color;
    }

    .filled-icon-catalog {
        background-image: url('../../../assets/img/icons/header/filled-icon-catalog.svg');
    }
    .filled-icon-pos {
        background-image: url('../../../assets/img/icons/header/filled-icon-pos.svg');
    }
    .filled-icon-help {
        background-image: url('../../../assets/img/icons/header/filled-icon-help.svg');
    }
    .filled-icon-profile {
        background-image: url('../../../assets/img/icons/header/filled-icon-profile.svg');
    }
    .filled-icon-search {
        background-image: url('../../../assets/img/icons/header/filled-icon-search.svg');
    }
    .filled-icon-shop {
        background-image: url('../../../assets/img/icons/header/filled-icon-shop.svg');
    }

    #icon-container-catalog,
    #icon-container-help,
    #icon-container-pos,
    #icon-container-profile,
    #icon-container-shop,
    #icon-container-search {
        transition: .2s ease-in-out;
    }
}
</style>