<template>
    <div class="position-relative w-100">
        <div class="parent-thumbnail position-relative w-100">
            <div class="thumbnail d-flex text-dark" :class="cardColor">
                <div class="w-100 h-100 position-absolute filter"/>
                <img v-if="book" :src="book.firstCoverPhotoUrl" class="book" alt="Spotlight book"/>
                <div class="description w-100 d-flex flex-column position-relative align-items-start h-100">
                    <img :src="cardIcon" alt="arrow-heart" class="col-5"/>
                    <div class="pb-2 pt-1">
                        <div class=" text-uppercase fw-bold primary-color" v-html="cardText"/>
                        <div
                        v-if="type === 'heart-stroke'"
                        class="publisher primary-color fw-bold"
                        v-html="`par Sébastien, de la librairie de l'harmattan`"/>
                    </div>
                    <div class="book-description overflow-hidden font-baskerville fw-bold">
                        <div :class="cardClass"/>
                        <div v-html="descriptionWithApostroph"/>
                    </div>
                    <div class="position-absolute bottom-0 end-0">
                        <div class="icons triple-plus"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bookPNG from "../../../assets/img/book.png";
import heartSVG from "../../../assets/img/logos/heart-logo.svg";
import criticsLogoSVG from "../../../assets/img/logos/critics-logo.svg";

export default {
    name: "SpotlightCard",
    props: {
        type: {
            type: String,
            required: true,
        },
        book: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        bookPNG,
        heartSVG,
        description: `Lorem Ipsum is simply dummy
            text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took
            a galley of type and scrambled it to make a type specimen
            book. It has survived not only five centuries, but also
            the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release
            of Letraset sheets containing Lorem Ipsum passages, and more
            recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.`,
    }),
    computed: {
        spotlightCardsTypeMetadata() {
            const spotlightCardsTypeMetadata = {
                "heart-stroke": { color: "heart-stroke", fadeClass: "red-fadeout", icon: heartSVG, text: this.$t("Home.spotlight.heartStroke") },
                "reviews": { color: "reviews", fadeClass: "yellow-fadeout", icon: criticsLogoSVG, text: this.$t("Home.spotlight.reviews") },
            };
            return spotlightCardsTypeMetadata[this.type] ? spotlightCardsTypeMetadata[this.type] : null;
        },
        cardColor() {
            return this.spotlightCardsTypeMetadata ? this.spotlightCardsTypeMetadata.color : null;
        },
        cardClass() {
            return this.spotlightCardsTypeMetadata ? this.spotlightCardsTypeMetadata.fadeClass : null;
        },
        cardIcon() {
            return this.spotlightCardsTypeMetadata ? this.spotlightCardsTypeMetadata.icon : null;
        },
        cardText() {
            return this.spotlightCardsTypeMetadata ? this.spotlightCardsTypeMetadata.text : null;
        },
        descriptionWithApostroph() {
            return `<span class="apostrophe">« </span>${this.description}<span class="apostrophe"> »</span>`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

/deep/ .apostrophe {
    font-family: 'Montserrat';
    line-height: 1;
    font-size: 1.25rem;

    @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 2.5rem;
    }
}

.heart-stroke {
    background-color: $pink-color;
}

.reviews {
    background-color: $yellow-color;
}

.parent-thumbnail {
    @include media-breakpoint-up(sm) {
        padding-bottom: 62.5%;
    }
}

.thumbnail {
    .filter {
        &:hover {
            backdrop-filter: brightness(1.1);
        }
    }

    .book {
        padding: 1rem;

        @include media-breakpoint-up(lg) {
            padding: 1.75rem;
        }
    }

    .description {
        padding: 1.5rem .5rem 1.5rem 0;
        font-size: 3vw;

        @include media-breakpoint-up(md) {
            font-size: 1.5vw;
        }

        @include media-breakpoint-up(xxl) {
            padding: 2rem .5rem 2rem 0;
        }

        .publisher {
            font-size: 2.5vw;

            @include media-breakpoint-up(md) {
                font-size: .8vw;
            }
        }

        .yellow-fadeout {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            background-image: -webkit-linear-gradient(bottom, rgba($yellow, 0) 70%, $yellow-color 100%);
            background-image: -moz-linear-gradient(to bottom, rgba($yellow, 0) 70%, $yellow-color 100%);
            background-image: -ms-linear-gradient(to bottom, rgba($yellow, 0) 70%, $yellow-color 100%);
            background-image: -o-linear-gradient(to bottom, rgba($yellow, 0) 70%, $yellow-color 100%);
            background-image: linear-gradient(to bottom, rgba($yellow, 0) 70%, $yellow-color 100%);
        }

        .red-fadeout {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            background-image: -webkit-linear-gradient(bottom, rgba($pink-color, 0) 70%, $pink-color 100%);
            background-image: -moz-linear-gradient(to bottom, rgba($pink-color, 0) 70%, $pink-color 100%);
            background-image: -ms-linear-gradient(to bottom, rgba($pink-color, 0) 70%, $pink-color 100%);
            background-image: -o-linear-gradient(to bottom, rgba($pink-color, 0) 70%, $pink-color 100%);
            background-image: linear-gradient(to bottom, rgba($pink-color, 0) 70%, $pink-color 100%);
        }

        .book-description {
            font-size: .75rem;

            @include media-breakpoint-up(xl) {
                font-size: 1rem;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 1.15rem;
            }
        }

        .triple-plus {
            margin: 1.25rem;

            @include media-breakpoint-up(lg) {
                margin: 2rem;
            }
        }
    }
}

</style>