<template>
    <div class="root position-relative w-100">
        <div class="parent-thumbnail position-relative w-100">
            <div class="thumbnail bg-secondary w-100 text-light">
                <div class="w-100 h-100 position-absolute filter">
                    <div class="w-100 d-flex position-absolute bottom-0">
                        <div
                        class="title position-relative text-center d-flex justify-content-center px-5 w-100 mb-2 font-baskerville fw-bold"
                        v-html="title"/>
                        <div class="position-absolute bottom-0 end-0">
                            <div class="icons book-icon"/>
                        </div>
                    </div>
                    <div class="text-uppercase type position-absolute top-0 d-flex start-0" v-html="type"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InspirationCard",
    props: {
        title: { type: String, required: true },
        type: { type: String, required: true },
        number: { type: Number, required: true },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '../../../assets/scss/_colors.scss';

.root {
    margin-bottom: .5rem;

    @include media-breakpoint-up(md) {
        margin: .25rem;
    }
}

.parent-thumbnail {
    @include media-breakpoint-up(sm) {
        padding-bottom: 68.75%;
    }
}

.thumbnail {
    background-image: url("../../../assets/inspiration.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .filter {
        &:hover {
            .book-icon {
                background-image: url("../../../assets/img/icons/filled-book-icon.svg");
            }

            .type {
                filter: brightness(1) !important;
            }
        }
    }
}
</style>