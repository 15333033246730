<template>
    <div class="d-none d-md-flex publisher text-dark flex-column px-4 justify-content-between col-md-4">
        <div class="fw-bold title" v-html="$t('Home.publisher.find')"/>
        <div v-for="i in 7" :key="i" class="editor d-flex justify-content-between">
            <div class="position-relative">
                <div v-html="'éditeur'"/>
                <div class="border-editor-name w-100 h-100 position-absolute bottom-0"/>
            </div>
            <div class="icons local-add"/>
        </div>
    </div>
</template>

<script>
export default { name: "PublishersList" };
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '../../../assets/scss/_colors.scss';

.publisher {
    .editor {
        border-bottom: 1px solid black;

        &:hover {
            .border-editor-name {
                opacity: 1;
                transform: translate(0, -1px);
            }

            .local-add {
                transition: .3s ease-in-out;
                background-image: url("../../../assets/img/icons/filled-add-icon.svg");
            }
        }
    }
}

.border-editor-name {
    transition: .3s ease-in-out;
    opacity: 0;
    border-bottom: 2px solid $primary-color;

    @include media-breakpoint-up(xl) {
        border-bottom: 4px solid $primary-color;
    }
}

.title {
    font-size: 1vw;
}

.editor {
    font-size: 3vw;

    @include media-breakpoint-up(md) {
        font-size: 1.25vw;
    };
}

.icons {
    width: 3vw;
    height: 3vw;

    @include media-breakpoint-up(md) {
        width: 1.5vw;
        height: 1.5vw;
    };
}

.local-add {
    background-image: url("../../../assets/img/icons/add-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
</style>