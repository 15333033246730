<template>
    <div class="images-row">
        <InspirationCard type="polar" title="Immersion dans les nuits de Stockholm" :number="6"/>
        <InspirationCard type="poésie" title="< Paris, je t'aime > Ode à la capitale" :number="3"/>
        <InspirationCard type="roman" title="Les pépites de Jane Austen" :number="12"/>
    </div>
</template>

<script>
import InspirationCard from "./InspirationCard/InspirationCard.vue";

export default {
    name: "InspirationsCards",
    components: { InspirationCard },
};
</script>