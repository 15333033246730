<template>
    <div>
        <div id="navbar" class="d-flex text-dark w-100 mb-2">
            <div class="nav-brand h-100 hover-pointer" @click="$router.push('/')"/>
            <div class="nav h-100 w-100">
                <div class="nav-top w-100 d-flex">
                    <div class="title align-items-center col-md-7 font-baskerville fw-bold" v-html="$t('NavBar.title')"/>
                    <div class="d-md-none icons menu d-flex position-absolute end-0 align-items-center justify-content-center" @click="showHeader"/>
                    <div class="icon-nav d-flex h-100 align-items-center col-md-5">
                        <NavTop :show-modal="showModal"/>
                    </div>
                </div>
                <div class="d-none d-md-flex nav-bottom w-100 text-light justify-content-cente">
                    <div class="d-flex align-items-center h-100 col-md-9">
                        <div class="d-flex align-items-center h-100 col-4">
                            <div class="numbers ps-4 pe-2 fw-lighter" v-html="'1'"/>
                            <div class="step-description fw-bold" v-html="$t('NavBar.steps.one')"/>
                        </div>
                        <div class="d-flex align-items-center h-100 col-4">
                            <div class="numbers ps-2 pe-2 fw-lighter" v-html="'2'"/>
                            <div class="step-description fw-bold" v-html="$t('NavBar.steps.two')"/>
                        </div>
                        <div class="d-flex align-items-center h-100 col-4">
                            <div class="numbers ps-2 pe-2 fw-lighter" v-html="'3'"/>
                            <div class="step-description fw-bold" v-html="$t('NavBar.steps.three')"/>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end col-md-3">
                        <div
                        id="video"
                        class="z-idx video-button d-flex justify-content-center align-items-center fw-bold" v-html="'Voir la vidéo'"/>
                    </div>
                </div>
                <div id="header-modals" class="d-none">
                    <CatalogModal :show-modal="showModal"/>
                    <HelpModal :show-modal="showModal"/>
                    <PosModal :show-modal="showModal"/>
                    <ProfileModal :show-modal="showModal"/>
                    <ShopModal :show-modal="showModal"/>
                    <SearchModal :show-modal="showModal"/>
                </div>
            </div>
            <div class="icon-nav-container d-md-none">
                <div ref="header" class="icon-nav d-flex h-100 align-items-center col-md-5 ">
                    <NavTop :show-modal="showModal"/>
                </div>
            </div>
        </div>
        <div
        ref="stepsHeader"
        class="d-md-none background-primary mobil-steps">
            <div class="d-flex align-items-center">
                <div class="numbers pe-2 d-flex justify-content-end fw-lighter col-2" v-html="'1'"/>
                <div class="step-description fw-bold ps-2" v-html="$t('NavBar.steps.one')"/>
            </div>
            <div class="d-flex align-items-center">
                <div class="numbers pe-2 d-flex justify-content-end fw-lighter col-2" v-html="'2'"/>
                <div class="step-description fw-bold ps-2" v-html="$t('NavBar.steps.two')"/>
            </div>
            <div class="d-flex align-items-center">
                <div class="numbers pe-2 d-flex justify-content-end fw-lighter col-2" v-html="'3'"/>
                <div class="step-description fw-bold ps-2" v-html="$t('NavBar.steps.three')"/>
            </div>
            <div class="d-flex justify-content-center">
                <div
                class="z-idx video-button d-flex justify-content-center align-items-center fw-bold" v-html="'Voir la vidéo'"/>
                <div
                class="z-idx understood-button"
                @click="animate"/>
            </div>
        </div>
    </div>
</template>

<script>
import NavTop from "./NavTop/NavTop";
import CatalogModal from "./CatalogModal/CatalogModal";
import HelpModal from "./HelpModal/HelpModal";
import PosModal from "./PosModal/PosModal";
import ProfileModal from "./ProfileModal/ProfileModal";
import ShopModal from "./ShopModal/ShopModal";
import SearchModal from "./SearchModal/SearchModal";

export default {
    name: "NavBar",
    components: { NavTop, CatalogModal, HelpModal, PosModal, ProfileModal, ShopModal, SearchModal },
    data: () => ({ lastSelected: null, selectedIcon: "" }),
    watch: {
        selectedIcon(val) {
            if (val !== "") {
                if (this.lastSelected) {
                    document.getElementById(this.lastSelected).classList.remove(`filled-icon-${this.lastSelected}`);
                    document.getElementById(`icon-container-${this.lastSelected}`).classList.remove("selected");
                    document.getElementById(`${this.lastSelected}Modal`).classList.add("d-none");
                }
                document.getElementById(val).classList.add(`filled-icon-${val}`);
                document.getElementById(`icon-container-${val}`).classList.add("selected");
                document.getElementById("header-modals").classList.remove("d-none");
                document.getElementById(`${val}Modal`).classList.remove("d-none");
            }
        },
    },
    methods: {
        showHeader() {
            if (!document.getElementById("header-modals").classList.contains("d-none")) {
                document.getElementById(this.selectedIcon).classList.remove(`filled-icon-${this.selectedIcon}`);
                document.getElementById(`icon-container-${this.selectedIcon}`).classList.remove("selected");
                document.getElementById("header-modals").classList.add("d-none");
                document.getElementById(`${this.selectedIcon}Modal`).classList.add("d-none");
                return;
            }
            if (this.$refs.header.classList.contains("activated")) {
                this.$refs.header.classList.remove("activated");
                this.$refs.header.classList.add("desactivated");
            } else {
                this.$refs.header.classList.add("activated");
                this.$refs.header.classList.remove("desactivated");
            }
        },
        showModal(modalName) {
            if (modalName === this.selectedIcon) {
                this.lastSelected = null;
                if (document.getElementById(modalName).classList.contains(`filled-icon-${modalName}`)) {
                    document.getElementById(modalName).classList.remove(`filled-icon-${modalName}`);
                    document.getElementById(`icon-container-${modalName}`).classList.remove("selected");
                    document.getElementById("header-modals").classList.add("d-none");
                    document.getElementById(`${modalName}Modal`).classList.add("d-none");
                } else {
                    document.getElementById(`icon-container-${this.selectedIcon}`).classList.add("selected");
                    document.getElementById(modalName).classList.add(`filled-icon-${modalName}`);
                    document.getElementById("header-modals").classList.remove("d-none");
                    document.getElementById(`${modalName}Modal`).classList.remove("d-none");
                }
            } else {
                this.lastSelected = this.selectedIcon;
                this.selectedIcon = modalName;
            }
        },
        animate() {
            this.$refs.stepsHeader.classList.add("animate_understood");
            setTimeout(() => {
                this.$refs.stepsHeader.classList.add("d-none");
            }, 1500);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_variables.scss';

.background-primary {
    background-color: $primary-color;
}

.mobil-steps {
    font-size: 0.8rem;
    margin: auto;

    .numbers {
        font-size: 2.25rem;
    }
}

#navbar {
    background-color: #fff;
    position: relative;
    height: $header-height;

    @include media-breakpoint-up(md) {
        height: $header-height-md;
    }

    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
    }

    @include media-breakpoint-up(xl) {
        height: $header-height-xl;
    }

    @include media-breakpoint-up(xxl) {
        height: $header-height-xxl;
    }

    .icon-nav-container {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        overflow-x: hidden;

        .icon-nav {
            @include media-breakpoint-down(md) {
                left: 100%;
                z-index: 10;
                padding-right: 4rem;
                position: absolute;
                width: 100%;
            }
        }

        .activated {
            left: 0;
            animation: slidein .5s;
        }

        .desactivated {
            left: 100%;
            animation: slideout .5s;
        }

        @keyframes slidein {
            from {
                left: 100%;
            }

            to {
                left: 0;
            }
        }

        @keyframes slideout {
            from {
                left: 0;
            }

            to {
                left: 100%;
            }
        }
    }

    .nav-brand {
        width: 8rem;
        z-index: 10;
        background-image: url("../../assets/img/logos/logo.png");
        background-repeat: no-repeat;
        background-size: contain;

        @include media-breakpoint-up(md) {
            width: 13rem;
        }

        @include media-breakpoint-up(lg) {
            width: 15rem;
        }

        @include media-breakpoint-up(xl) {
            width: 18rem;
        }

        @include media-breakpoint-up(xxl) {
            width: 25rem;
        }
    }

    .nav {
        width: 70%;
        -webkit-box: 1;
        -moz-box: 1;
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;

        @include media-breakpoint-up(md) {
            position: relative;
        }

        @include media-breakpoint-up(lg) {
            -webkit-box-direction: normal;
            -webkit-box-orient: vertical;
            -moz-box-direction: normal;
            -moz-box-orient: vertical;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 80%;
            padding-left: 3rem;
        }

        .nav-top {
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -ms-flex-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            height: 100%;

            @include media-breakpoint-up(md) {
                height: 50%;
            }

            .title {
                display: -webkit-box;
                display: -moz-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding: 0 4rem 0 .5rem;
                font-size: 1rem;
                line-height: 1;
                color: $primary-color;

                @include media-breakpoint-up(sm) {
                    font-size: 1.5rem;
                }

                @include media-breakpoint-up(lg) {
                    padding: 0;
                }

                @include media-breakpoint-up(xxl) {
                    font-size: 2.5rem;
                }
            }

            .menu {
                z-index: 40;
                width: 4rem;
                height: 4rem;
            }
        }
    }
}

#header-modals {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100vh;
    z-index: 30;
    top: 0;
    padding-left: 5%;

    @include media-breakpoint-up(md) {
        padding-left: 0;
        margin-top: $header-height-md / 2;
        min-height: calc(100vh - #{$header-height-md / 2});
    }

    @include media-breakpoint-up(lg) {
        padding-left: 3rem;
        margin-top: $header-height-lg / 2;
        min-height: calc(100vh - #{$header-height-lg / 2});
    }

    @include media-breakpoint-up(xl) {
        margin-top: $header-height-xl / 2;
        min-height: calc(100vh - #{$header-height-xl / 2});
    }

    @include media-breakpoint-up(xxl) {
        margin-top: $header-height-xxl / 2;
        min-height: calc(100vh - #{$header-height-xxl / 2});
    }
}

.nav-bottom {
    background-color: $primary-color;
    font-size: 3rem;
    overflow-x: initial;
    position: relative;
    height: 50%;

    .z-idx {
        z-index: 30;
    }

    .step-description {
        font-size: .5rem;

        @include media-breakpoint-up(lg) {
            font-size: .6rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: .75rem;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 1rem;
        }
    }

    .numbers {
        font-size: 3rem;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        max-height:100%;

        @include media-breakpoint-up(xxl) {
            font-size: 4rem;
        }
    }
}

.video-button {
    height: 100%;
    background-color: #fff;
    border: .5rem solid $primary-color;
    color: black;
    padding: .5rem;
    text-transform: uppercase;
    font-size: .75rem;
    transition: .2s ease-in-out;

    &:hover {
        transition: .2s ease-in-out;
        cursor: pointer;
        background-color: #000;
        color: #fff;
    }

    @include media-breakpoint-up(lg) {
        border: .75rem solid $primary-color;
    }

    @include media-breakpoint-up(xxl) {
        border: 1rem solid $primary-color;
        font-size: 1.5rem;
    }
}

.understood-button {
    background-image: url("../../assets/img/agree.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.understood-button, .video-button {
    width: 8rem;
    min-width: 8rem;

    &:hover {
        cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
        min-width: 10rem;
    }

    @include media-breakpoint-up(xxl) {
        width: 15rem;
        min-width: 15rem;
    }
}

.animate_understood {
    transition: 2s;
    opacity: 0;
}
</style>