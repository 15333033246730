<template>
    <div class="position-relative w-100">
        <div class="parent-thumbnail position-relative w-100">
            <div class="thumbnail bg-secondary w-100 text-light text-uppercase">
                <div class="position-absolute h-100 w-100">
                    <div class="position-absolute filter d-flex end-0 number justify-content-end p-3">
                        <div class="icons filled-book-icon"/>
                        <div class="type position-absolute top-0 start-0 d-flex" v-html="type"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdvertisingCard",
    props: {
        type: { type: String, required: true },
        number: { type: Number, required: true },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '../../../assets/scss/_colors.scss';

.parent-thumbnail {
    @include media-breakpoint-up(md) {
        padding-bottom: 0;
        height: 10vw;
    }
}

.thumbnail {
    .number {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        align-items: flex-end;

        @include media-breakpoint-up(md) {
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }
    }

    .filter {
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
    }

    .filled-book-icon {
        width: 3rem;
        height: 3rem;

        @include media-breakpoint-up(xxl) {
            width: 6rem;
            height: 6rem;
        }
    }
}
</style>