<template>
    <div id="app">
        <NavBar/>
        <router-view/>
        <ShopFooter/>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue";
import ShopFooter from "@/components/ShopFooter/ShopFooter.vue";

export default {
    name: "App",
    components: {
        NavBar,
        ShopFooter,
    },
};
</script>

<style lang="scss">
@import './assets/scss/_colors.scss';
#app {
    position: relative;
    max-width: 2400px;
    margin: auto;
}
</style>