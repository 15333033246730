<template>
    <div class="d-flex justify-content-center align-items-start">
        <div ref="number" v-html="getPrice(price).number"/>
        <div ref="symbol" class="tiny" v-html="getPrice(price).symbol"/>
        <div ref="decimal" class="tiny" v-html="getPrice(price).decimal"/>
    </div>
</template>

<script>
import { getPrice } from "@/helpers/functions/Price.js";

export default {
    name: "Price",
    props: { price: { type: Number, required: true } },
    data: () => ({ getPrice }),
};
</script>

<style lang="scss" scoped>
.tiny {
    font-size: 70%;
}
</style>