<template>
    <div class="button position-relative p-2 fw-bold">
        <Price :price="price"/>
        <div class="position-absolute w-100 d-flex justify-content-end p-2">
            <div class="icons"/>
        </div>
    </div>
</template>

<script>
import Price from "@/components/Shared/Buttons/PurchasedButton/Price/Price";

export default {
    name: "PurchasedButton",
    components: { Price },
    props: { price: { type: Number, required: true } },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.button {
    &:hover {
        .icons {
            background-image: url("../../../../assets/img/icons/shop-icon.svg");
        }
    }
}

.icons {
    background-image: url("../../../../assets/img/icons/filled-shop-icon-white-border.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
</style>