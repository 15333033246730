<template>
    <div id="catalogModal" class="d-none">
        <div class="title d-flex justify-content-between">
            <div class="d-flex align-items-center fw-bold" v-html="$t('NavBar.iconsModals.catalog.title')"/>
            <div class="d-flex align-items-center">
                <div
                class="icons cross"
                @click="showModal('catalog')"/>
            </div>
        </div>
        <div class="content w-100 position-relative">
            <div v-html="'Littérature'"/>
            <div v-html="'Littérature'"/>
            <div v-html="'Littérature'"/>
            <div v-html="'Littérature'"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "CatalogModal",
    props: { showModal: { type: Function, required: true } },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

#catalogModal {
    background-color: $primary-color;

    .content {
        font-weight: 700;
        padding: 0 1.5rem;
        color: white;
        background-color: $primary-color;
    }
}
</style>