import Axios from "axios";
import Config from "../../../config";
import { stringify } from "qs";

const GutenbergAPI = {
    // eslint-disable-next-line no-unused-vars,max-lines-per-function
    install(Vue) {
        const axiosConfig = {
            baseURL: Config.API.gutenberg.baseURL,
            timeout: 0,
            headers: { "Content-Type": "application/json" },
        };
        const axios = Axios.create(axiosConfig);

        Vue.prototype.$gutenbergAPI = {};

        Vue.prototype.$gutenbergAPI.getProducts = queryStrings => axios.get(`/products?${stringify(queryStrings)}`);

        Vue.prototype.$gutenbergAPI.getProduct = (id, queryStrings = {}) => axios.get(`/products/${id}?${stringify(queryStrings)}`);

        Vue.prototype.$gutenbergAPI.login = user => axios.post(`/users/login`, user);
    },
};

export default GutenbergAPI;