<template>
    <div class="content position-relative h-100">
        <div class="d-flex flex-column justify-content-between h-100">
            <div class="d-flex w-100 align-items-center">
                <div class="icons filled-star"/>
                <div class="p-2 fs-3" v-html="$t('ShoppingBasket.title')"/>
            </div>
            <div class="" v-html="$t('ShoppingBasket.subtitle')"/>
            <div v-for="i in 3" :key="i" class="p-1">
                <div class="fs-5 border border-1 border-top-0 border-gray border-end-0 border-start-0" v-html="'test'"/>
            </div>
            <div class="d-flex align-items-center">
                <div class="icons shop"/>
                <div class="fs-4 p-2 hover-cursor" v-html="$t('ShoppingBasket.myOrders')"/>
            </div>
        </div>
    </div>
</template>

<script>
export default { name: "ShoppingBasket" };
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_colors.scss";

.content {
    color: white;
    display: block;
    background-color: $primary-color;
    width: 100%;
    padding: 1rem;
    height: 15rem;
    transition: .2s ease-in-out;

    &:hover {
        background-color: $secondary-color;

        .filled-star {
            background-image: url("../../../assets/img/icons/filled-white-star-icon.svg");
        }
    }
}

</style>