<template>
    <div class="position-relative w-100">
        <div class="thumbnail d-flex text-dark col-md-8">
            <div class="w-100 h-100 position-absolute filter"/>
            <div class="author d-flex align-items-center flex-column position-relative col-3 col-md-2 ">
                <img :src="moonSVG" alt="arrow-moon" class="moon"/>
                <div class="book-container d-flex h-0 justify-content-center">
                    <img :src="michelSVG" alt="BedsideBook" class="book"/>
                </div>
            </div>
            <div class="book-description d-flex flex-column position-relative col-9 col-md-7">
                <div class="author-description d-flex flex-column">
                    <div class="fw-bold text-uppercase primary-color" v-html="$t('Home.bedsidebook')"/>
                    <div class="text-light fw-bold lh-1" v-html="'Michel Winock, écrivain'"/>
                </div>
                <div class="d-none d-md-block description text-light overflow-hidden font-baskerville fw-bold">
                    <div class="d-none d-md-block fw-bold text-light py-2 book-name" v-html="`${book.title} / ${book.author}`"/>
                    <div class="fadeout"/>
                    <div v-html="descriptionWithApostroph"/>
                </div>
                <div class="position-absolute bottom-0 end-0">
                    <div class="icons triple-plus"/>
                </div>
                <div class="d-md-none d-flex h-75">
                    <img :src="book.firstCoverPhotoUrl" alt="BedsideBook" class="book pe-3"/>
                    <div>
                        <div class="text-light fw-bold" v-html="book.title"/>
                        <div class="text-light" v-html="book.author"/>
                    </div>
                </div>
            </div>
            <div class="d-none d-md-flex align-items-center justify-content-center col-md-3 p-1">
                <div class="book-container d-flex justify-content-end">
                    <img :src="book.firstCoverPhotoUrl" alt="BedsideBook" class="book"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moonSVG from "../../../assets/img/logos/moon-logo-white.svg";
import michelSVG from "../../../assets/tmp-michel.svg";

export default {
    name: "BedsideBook",
    props: {
        book: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        moonSVG,
        michelSVG,
        description: `Lorem ipsum dolor sit amet
            adipiscing elit sodales primis,
            mollis viverra conubia ligula inceptos
            laoreet libero tortor, nascetur non
            Lorem ipsum dolor sit amet
            adipiscing elit sodales primis,
            mollis viverra conubia ligula inceptos
            laoreet libero tortor, nascetur non`,
    }),
    computed: {
        descriptionWithApostroph() {
            return `<span class="apostrophe">« </span>${this.description}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

/deep/ .apostrophe {
    font-family: 'Montserrat';
    line-height: 1;
    font-size: 1.25rem;

    @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 2.5rem;
    }
}

.thumbnail {
    background-color: $secondary-color;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
        padding: 1.25rem;
    }

    @include media-breakpoint-up(xxl) {
        padding: 2rem;
    }

    .filter {
        left: 0;
        top: 0;

        &:hover {
            backdrop-filter: brightness(1.1);
        }
    }

    .author {
        .moon {
            width: 100%;

            @include media-breakpoint-up(md) {
                padding-bottom: 1rem;
                padding-top: .5rem;
            }
        }

        .book-container {
            padding-top: 1rem;

            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }
    }

    .book-description {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        font-size: 3vw;
        padding-left: 1rem;

        @include media-breakpoint-up(md) {
            font-size: 2vw;
        }

        .description {
            font-size: .75rem;

            @include media-breakpoint-up(xl) {
                font-size: 1rem;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 1.15rem;
            }

            .book-name {
                font-size: .65rem;

                @include media-breakpoint-up(lg) {
                    font-size: .85rem;
                }
            }
        }

        .fadeout {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: -webkit-linear-gradient(bottom, rgba($secondary-color, 0) 70%, $secondary-color 100%);
            background-image: -moz-linear-gradient(to bottom, rgba($secondary-color, 0) 70%, $secondary-color 100%);
            background-image: -ms-linear-gradient(to bottom, rgba($secondary-color, 0) 70%, $secondary-color 100%);
            background-image: -o-linear-gradient(to bottom, rgba($secondary-color, 0) 70%, $secondary-color 100%);
            background-image: linear-gradient(to bottom, rgba($secondary-color, 0) 70%, $secondary-color 100%);
        }

        .author-description {
            font-size: 3.5vw;

            @include media-breakpoint-down(md) {
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -ms-flex-pack: justify;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                padding-bottom: .5rem;
                height: 40%;
            }

            @include media-breakpoint-up(md) {
                font-size: 1.5vw;
            }
        }
    }
}
</style>