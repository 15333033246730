<template>
    <Splide
    :options="{
        rewind: false,
        perPage: getSlidesNumber,
        perMove: 1,
        arrows: true,
        pagination: false,
        breakpoints: {
            576: {
                perPage: 2,
            },
            768: {
                perPage: 3,
            },
            1200: {
                perPage: 4,
            },
        },
    }"
    @splide:move="onMove">
        <SplideSlide v-for="(item, i) in books" :key="i">
            <BookWindow :book="item"/>
        </SplideSlide>
        <template #controls>
            <div class="splide__arrows" :class="getSlidesNumber <= 4 ? 'd-none' : null">
                <button ref="left-arrow" class="splide__arrow splide__arrow--prev arrow-left icons d-none"/>
                <button ref="right-arrow" class="splide__arrow splide__arrow--next arrow-right icons"/>
            </div>
        </template>
    </Splide>
</template>

<script>
import BookWindow from "@/components/Shared/Slider/BookWindow/BookWindow.vue";

export default {
    name: "BookSlider",
    components: { BookWindow },
    props: { books: { type: Array, required: true }, nbWindow: { type: Number, default: 6 } },
    data() {
        return {
            tablet: false,
            type: "lg",
        };
    },
    computed: {
        slidesNumber() {
            const slidesNumber = {
                xs: 2,
                sm: 3,
                md: 4,
                lg: this.nbWindow || 6,
            };
            return slidesNumber[this.type] ? slidesNumber[this.type] : null;
        },
        getSlidesNumber() {
            return this.slidesNumber ? this.slidesNumber : 6;
        },
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        onResize() {
            const width = window.innerWidth;
            if (width < 576) {
                this.type = "xs";
            } else if (width >= 576 && width < 768) {
                this.type = "sm";
            } else if (width >= 768 && width <= 1200) {
                this.type = "md";
            } else {
                this.type = "lg";
            }
        },
        onMove(destSplide, nextIndex) {
            if (nextIndex === 0) {
                this.$refs["left-arrow"].classList.add("d-none");
            } else if (this.$refs["left-arrow"].classList.contains("d-none")) {
                this.$refs["left-arrow"].classList.remove("d-none");
            }
            if (nextIndex === this.books.length - (this.getSlidesNumber + 1)) {
                this.$refs["right-arrow"].classList.add("d-none");
            } else if (this.$refs["right-arrow"].classList.contains("d-none")) {
                this.$refs["right-arrow"].classList.remove("d-none");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.vueperslides__arrow {
    opacity: 1 !important;

    .arrow-right, .arrow-left {
        position: relative;
        width: 2rem !important;
        height: 2rem !important;

        @include media-breakpoint-up(md) {
            width: 2.5rem !important;
            height: 2.5rem !important;
        }
    }
}

.icons {
    top: 40% !important;
}
</style>