<template>
    <div id="profileModal" class="d-none d-flex flex-column">
        <div class="title d-flex justify-content-between">
            <div class="d-flex align-items-center fw-bold" v-html="$t('NavBar.iconsModals.profile.title')"/>
            <div class="d-flex align-items-center">
                <div
                class="d-none d-md-block icons cross"
                @click="showModal('profile')"/>
                <div
                class="d-md-none icons profile-transparent"
                @click="showModal('profile')"/>
            </div>
        </div>
        <div class="content w-100 position-relative">
            <div class="border-primary-color position-absolute w-100 top-0 start-0"/>
            <div class="d-flex position-relative profile-header">
                <div
                ref="login"
                class="login align-items-end hover-pointer d-flex text-uppercase primary-color fw-bold selected"
                @click="swapCategories('register')"
                v-html="$t('NavBar.iconsModals.profile.login.title')"/>
                <div
                ref="register"
                class="register align-items-end hover-pointer d-flex text-uppercase primary-color fw-bold"
                @click="swapCategories('login')"
                v-html="$t('NavBar.iconsModals.profile.register.title')"/>
            </div>
            <LoginTab v-if="loginTab"/>
            <RegisterTab v-else/>
        </div>
    </div>
</template>

<script>
import LoginTab from "./Login/LoginTab";
import RegisterTab from "./Register/RegisterTab";

export default {
    name: "ProfileModal",
    components: { LoginTab, RegisterTab },
    props: { showModal: { type: Function, required: true } },
    data: () => ({ loginTab: true }),
    methods: {
        swapCategories(category) {
            if (category === "login") {
                this.$refs.register.classList.add("selected");
                this.$refs.login.classList.remove("selected");
                this.loginTab = false;
            } else {
                this.loginTab = true;
                this.$refs.register.classList.remove("selected");
                this.$refs.login.classList.add("selected");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

#profileModal {
    background-color: $green-color;

    .content {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box: 1;
        -moz-box: 1;
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
        padding: .5rem 1rem;
        border: .75rem solid $green-color;
        border-top: 0;
        padding-top: 0;

        @include media-breakpoint-up(md) {
            border: .9rem solid $green-color;
            border-top: 0;
        }
    }

    .selected {
        border-bottom: 8px solid $primary-color;
    }

    .border-primary-color {
        border-bottom: 1px solid $primary-color;
        height: calc(4rem - 4px);
    }

    .profile-header {
        height: 4rem;
    }

    .login {
        margin-right: 1rem;

        @include media-breakpoint-up(md) {
            margin-right: 2rem;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 3rem;
        }
    }

    .login, .register {
        font-size: 1rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 1.5rem;
        }
    }
}
</style>