<template>
    <div class="pages text-dark">
        <div class="pb-3 d-flex align-items-end">
            <div class="icons home"/>
            <div v-html="`> ${$route.params.item}`"/>
        </div>
        <div class="topline w-100 text-light d-flex align-items-center">
            <div class="col-6">
                <div class="fw-bold text-uppercase" v-html="'Inspirations'"/>
                <div class="text-uppercase" v-html="'Polar'"/>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center">
                <div class="icons book-icon"/>
            </div>
        </div>
        <div class="cards-content d-md-flex pb-5 w-100">
            <div v-for="(item, i) in books" :key="i" class="items wood-bg">
                <div class="item d-flex w-100 p-2">
                    <div class="book col-4 d-flex flex-column justify-content-around align-items-md-center">
                        <div class="book-icons d-flex justify-content-center">
                            <div class="icons star border-0 mx-1"/>
                            <div class="icons share border-0 mx-1"/>
                        </div>
                        <img :src="bookPNG" class="shadow" alt="BedsideBook"/>
                        <div class="d-flex w-100 justify-content-around  aling-items-center">
                            <div class="d-none d-lg-flex icons add"/>
                            <Price class="price fw-bold" :price="18.99"/>
                            <div class="icons filled-shop"/>
                        </div>
                    </div>
                    <div class="book-description col-8 d-flex flex-column align-items-center position-relative pt-2 overflow-hidden">
                        <div class="bookname fw-bold" v-html="'title'"/>
                        <div class="author fst-italic" v-html="`Auteur livre`"/>
                        <div ref="summary" class="less summary p-2 position-relative">
                            <div ref="fadeout" class="fadeout"/>
                            <div class="position-absolute bottom-0 d-flex justify-content-center w-100">
                                <div class="icons " @click="changeSummaryDisplay(i)"/>
                            </div>
                            Sed que eaque viducilit vendi
                            volest, ut laborpor autem
                            fugitibusae volut fuga. Nequi que
                            voles illigni minulla in eicipictur,
                            vitisitatur aut alicid es ne ipide
                            quaspe nus autaquo de vel idem
                            lignatia doluptasini nos et, ea
                            natus vendipsa dit eicium quiatus
                            Sed que eaque viducilit vendi
                            volest, ut laborpor autem
                            fugitibusae volut fuga. Nequi que
                            voles illigni minulla in eicipictur,
                            vitisitatur aut alicid es ne ipide
                            quaspe nus autaquo de vel idem
                            lignatia doluptasini nos et, ea
                            natus vendipsa dit eicium quiatus
                            fugitibusae volut fuga. Nequi que
                            voles illigni minulla in eicipictur,
                            vitisitatur aut alicid es ne ipide
                            quaspe nus autaquo de vel idem
                            lignatia doluptasini nos et, ea
                            natus vendipsa dit eicium quiatus
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InspirationsCards class="pb-3"/>
        <div class="d-lg-none">
            <div class="pb-2" v-html="$t('Product.booksSeen')"/>
            <BookSlider v-if="books" :books="books" :nb-window="4"/>
            <ShoppingBasket class="mt-3"/>
        </div>
        <div class="d-none d-lg-flex row align-items-center h-100">
            <div class="pb-2" v-html="$t('Product.booksSeen')"/>
            <div class="col-8 pe-5">
                <BookSlider v-if="books" :books="books" :nb-window="4"/>
            </div>
            <div class="col-4">
                <ShoppingBasket/>
            </div>
        </div>
    </div>
</template>

<script>

import bookPNG from "@/assets/img/book.png";
import InspirationsCards from "@/components/Shared/InspirationsCards.vue";
import ShoppingBasket from "@/components/Shared/ShoppingBasket/ShoppingBasket.vue";
import BookSlider from "@/components/Shared/Slider/BookSlider/BookSlider.vue";
import { getPrice } from "../../helpers/functions/Price.js";
import Price from "@/components/Shared/Buttons/PurchasedButton/Price/Price";

export default {
    name: "Item",
    components: { InspirationsCards, ShoppingBasket, BookSlider, Price },
    data: () => ({
        books: [
            { price: 18, author: "author", title: "test" },
            { price: 18, author: "author", title: "test" },
            { price: 18, author: "author", title: "test" },
            { price: 18, author: "author", title: "test" },
            { price: 18, author: "author", title: "test" },
        ],
        bookPNG,
        getPrice,
    }),
    methods: {
        changeSummaryDisplay(i) {
            if (this.$refs.summary[i].classList.contains("less")) {
                this.$refs.summary[i].classList.remove("less");
                this.$refs.fadeout[i].classList.add("d-none");
            } else {
                this.$refs.summary[i].classList.add("less");
                this.$refs.fadeout[i].classList.remove("d-none");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

.pages {
    .topline {
        background-color: $primary-color;
        padding: 1rem;

        @include media-breakpoint-up(xxl) {
            padding: 2rem;
            font-size: 2rem;
            line-height: 1.2;
        }

        .book-icon {
            width: 3rem;
            height: 3rem;

            @include media-breakpoint-up(xxl) {
                width: 5rem;
                height: 5rem;
            }
        }
    }

    .cards-content {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        @include media-breakpoint-up(xxl) {
            -webkit-box-direction: normal;
            -webkit-box-orient: horizontal;
            -moz-box-direction: normal;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: initial;
            -ms-flex-direction: initial;
            flex-direction: initial;
        }

        .items {
            padding: 1rem;

            @include media-breakpoint-up(xxl) {
                padding: 2rem;
                width: 50%;
            }

            &.wood-bg {
                background-image: url("../../assets/img/wood-horizontal.svg");
                background-repeat: repeat;
                background-position: center;
            }

            .item {
                height: 100%;
                background-color: #fff;

                .book-description {
                    font-size: .9rem;

                    @include media-breakpoint-up(md) {
                        padding: 0 2rem;
                        font-size: 1.2rem;

                        .bookname, .author {
                            font-size: 2rem;
                        }
                    }

                    .less {
                        display: -webkit-box;
                        -webkit-line-clamp: 10;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                     .fadeout {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-image: -webkit-linear-gradient(bottom, rgba(#fff, 0) 80%, #fff 100%);
                        background-image: -moz-linear-gradient(to bottom, rgba(#fff, 0) 80%, #fff 100%);
                        background-image: -ms-linear-gradient(to bottom, rgba(#fff, 0) 80%, #fff 100%);
                        background-image: -o-linear-gradient(to bottom, rgba(#fff, 0) 80%, #fff 100%);
                        background-image: linear-gradient(to bottom, rgba(#fff, 0) 80%, #fff 100%);
                    }
                }

                .price {
                    @include media-breakpoint-up(xl) {
                        font-size: 1.5rem;
                    }
                }

                .icons {
                    width: 1.5rem;
                    height: 1.5rem;

                    @include media-breakpoint-up(xxl) {
                            width: 2.2rem;
                            height: 2.2rem;
                    }
                }
            }
        }
    }
}
</style>