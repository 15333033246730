import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/Home/Home";
import Product from "@/components/Product/Product";
import NotFound from "@/components/NotFound/NotFound";
import CGV from "@/components/CGV/CGV";
import About from "@/components/About/About";
import ShopBasket from "@/components/ShopBasket/ShopBasket";
import Order from "@/components/Order/Order";
import Contact from "@/components/Contact/Contact";
import Profile from "@/components/Profile/Profile";
import Item from "@/components/Item/Item";

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/404",
        name: "NotFound",
        component: NotFound,
    },
    {
        path: "/CGV",
        name: "CGV",
        component: CGV,
    },
    {
        path: "/a-propos",
        name: "About",
        component: About,
    },
    {
        path: "/panier",
        name: "ShopBasket",
        component: ShopBasket,
    },
    {
        path: "/passage-de-commande",
        name: "Order",
        component: Order,
    },
    {
        path: "/profil",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/item/:item",
        name: "Item",
        component: Item,
    },
    {
        path: "/:bookId",
        name: "Product",
        component: Product,
    },
    {
        path: "*",
        redirect: "/404",
    },
];
const Router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default Router;