<template>
    <div id="helpModal" class="d-none d-flex flex-column">
        <div class="title d-flex justify-content-between">
            <div class="title d-flex align-items-center fw-bold" v-html="$t('NavBar.iconsModals.help.title')"/>
            <div class="d-flex align-items-center">
                <div
                class="icons cross"
                @click="showModal('help')"/>
            </div>
        </div>
        <div class="content w-100 position-relative">
            <div v-html="'Littérature'"/>
            <div v-html="'Littérature'"/>
            <div v-html="'Littérature'"/>
            <div v-html="'Littérature'"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "HelpModal",
    props: { showModal: { type: Function, required: true } },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/scss/_colors.scss';

#helpModal {
    background-color: $help-color;

    .title {
        color: $primary-color;
    }

    .content {
        font-weight: 700;
        padding: .5rem 1.5rem;
        border: .75rem solid $help-color;
        color: $primary-color;
        border-top: 0;

        @include media-breakpoint-up(md) {
            border: .9rem solid $help-color;
            border-top: 0;
        }
    }
}
</style>