<template>
    <div class="product-card w-100">
        <div class="content d-flex w-100">
            <div class="d-flex">
                <div class="book-cover d-flex flex-column pe-3 ps-1">
                    <div class="book-icons d-flex pb-3 justify-content-center">
                        <div class="icons star border-0 mx-1"/>
                        <div class="icons share border-0 mx-1"/>
                    </div>
                    <img :src="selectedBookFace" class="shadow book" alt="BedsideBook"/>
                    <div class="previsualisation">
                        <img
                        ref="cover"
                        :src="getBook.firstCoverPhotoUrl"
                        class="shadow hover-pointer selected-book"
                        alt="BedsideBook"
                        @click="(e) => swapBookFace(e)"/>
                        <img
                        ref="back"
                        :src="getBook.fourthCoverPhotoUrl"
                        class="shadow hover-pointer"
                        alt="BedsideBook"
                        @click="(e) => swapBookFace(e)"/>
                    </div>
                </div>
                <div class="book-description d-flex flex-column align-items-center">
                    <div class="bookname fw-bold text-center font-baskerville" v-html="book.title"/>
                    <div class="author fst-italic text-center pb-2 font-baskerville" v-html="book.author"/>
                    <div class="section fw-bold d-flex w-100 text-uppercase">
                        <div
                        ref="sectionResume"
                        class="selected-section"
                        @click="swapSection"
                        v-html="$t('Product.content.summary')"/>
                        <div
                        ref="sectionCarac"
                        @click="swapSection"
                        v-html="$t('Product.content.caracteristics')"/>
                    </div>
                    <div class="d-flex h-100">
                        <div ref="summary" class="less summary position-relative font-baskerville">
                            <div ref="fadeout" class="fadeout"/>
                            <div class="position-absolute bottom-0 d-flex justify-content-center w-100">
                                <div ref="icon" class="icons arrow-bottom" @click="changeSummaryDisplay"/>
                            </div>
                            {{ book.description }}
                        </div>
                        <ProductFooter v-if="getBook.price" :price="getBook.price" class="d-none d-md-flex w-50"/>
                    </div>
                </div>
            </div>
            <ProductFooter v-if="getBook.price" :price="getBook.price" class="d-md-none"/>
        </div>
    </div>
</template>

<script>
import bookPNG from "@/assets/img/book.png";
import ProductFooter from "@/components/Product/ProductCard/ProductFooter/ProductFooter";

export default {
    name: "ProductCard",
    components: { ProductFooter },
    props: {
        book: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        bookPNG,
        selectedBookFace: undefined,
        selectedSection: "section-resume",
    }),
    computed: {
        getSelectedBookFace() {
            if (this.selectedBookFace) {
                return "";
            }
            return this.selectedBookFace;
        },
        getBook() {
            return this.book;
        },
    },
    watch: {
        book() {
            this.selectedBookFace = this.book.firstCoverPhotoUrl;
        },
    },
    mounted() {
        this.selectedBookFace = this.book.firstCoverPhotoUrl;
    },
    methods: {
        changeSummaryDisplay() {
            if (this.$refs.summary.classList.contains("less")) {
                this.$refs.summary.classList.remove("less");
                this.$refs.fadeout.classList.add("d-none");
                this.$refs.icon.classList.remove("arrow-bottom");
                this.$refs.icon.classList.add("arrow-up");
            } else {
                this.$refs.summary.classList.add("less");
                this.$refs.fadeout.classList.remove("d-none");
                this.$refs.icon.classList.add("arrow-bottom");
                this.$refs.icon.classList.remove("arrow-up");
            }
        },
        swapBookFace(e) {
            if (e.target === this.$refs.cover && this.selectedBookFace !== this.book.firstCoverPhotoUrl) {
                this.$refs.cover.classList.add("selected-book");
                this.$refs.back.classList.remove("selected-book");
                this.selectedBookFace = this.book.firstCoverPhotoUrl;
            } else if (e.target === this.$refs.back && this.selectedBookFace !== this.book.fourthCoverPhotoUrl) {
                this.$refs.back.classList.add("selected-book");
                this.$refs.cover.classList.remove("selected-book");
                this.selectedBookFace = this.book.fourthCoverPhotoUrl;
            }
        },
        swapSection() {
            if (this.$refs.sectionResume.classList.contains("selected-section")) {
                this.$refs.sectionResume.classList.remove("selected-section");
                this.$refs.sectionCarac.classList.add("selected-section");
            } else {
                this.$refs.sectionResume.classList.add("selected-section");
                this.$refs.sectionCarac.classList.remove("selected-section");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import "../../../assets/scss/_colors.scss";

.product-card {
    background-image: url("../../../assets/img/light-wood-vertical.jpg");
    padding: 1rem;

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }

    .content {
        background-color: #fff;
        font-size: .8rem;
        padding: .5rem;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        @include media-breakpoint-up(xxl) {
            -webkit-box-orient: initial;
            -webkit-box-direction: initial;
            -ms-flex-direction: initial;
            -moz-box-orient: horizontal;
            -webkit-flex-direction: initial;
            -ms-flex-direction: initial;
            flex-direction: initial;
            padding: 2rem 0 2rem 5em;
        }

        .book-icons {
            @include media-breakpoint-up(xxl) {
                img {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }

        .book-cover {
            width: 40%;

            @include media-breakpoint-up(md) {
                max-width: 25%;
            }

            img {
                border: 2px solid lightgray;
            }

            .previsualisation {
                display: -webkit-box;
                display: -moz-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding: 1rem 0;
                -webkit-box-pack: justify;
                -moz-box-pack: justify;
                -ms-flex-pack: justify;
                -webkit-justify-content: space-between;
                justify-content: space-between;

                @include media-breakpoint-up(xxl) {
                    -webkit-box-pack: center;
                    -moz-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                }

                img {
                    max-width: 45%;

                    @include media-breakpoint-up(xxl) {
                        margin: .5rem;
                        max-width: 5rem;
                    }
                }

                .selected-book {
                    border: 3px solid $primary-color;
                }
            }
        }

        .book-description {
            width: 60%;

            @include media-breakpoint-up(md) {
                padding: 0 2rem;
                font-size: 1.2rem;
                width: 75%;

                .bookname, .author {
                    font-size: 1.5rem;
                }
            }

            @include media-breakpoint-up(xxl) {
                padding: 0 4rem;
            }

            .summary {
                font-size: .8rem;
                padding-top: 1rem;

                @include media-breakpoint-up(md) {
                    width: 50%;
                    padding-right: 1.5rem;
                }
            }

            .less {
                display: -webkit-box;
                -webkit-line-clamp: 15;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .section {
                color: $primary-color;
                border-bottom: 1px solid black;
                font-size: 2vw;

                div {
                    margin-right: 2.5rem;

                    &:hover {
                        cursor: pointer;
                    }
                }

                @include media-breakpoint-up(md) {
                    font-size: 1vw;
                }
            }

            .selected-section {
                border-bottom: 4px solid $primary-color;
            }

            .fadeout {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: -webkit-linear-gradient(bottom, rgba(#fff, 0) 70%, #fff 100%);
                background-image: -moz-linear-gradient(to bottom, rgba(#fff, 0) 70%, #fff 100%);
                background-image: -ms-linear-gradient(to bottom, rgba(#fff, 0) 70%, #fff 100%);
                background-image: -o-linear-gradient(to bottom, rgba(#fff, 0) 70%, #fff 100%);
                background-image: linear-gradient(to bottom, rgba(#fff, 0) 80%, #fff 100%);
            }
        }

        .misc {
            @include media-breakpoint-up(xxl) {
                width: 50%;
            }
        }
    }
}
</style>